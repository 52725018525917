
import { Vue, Component } from 'vue-property-decorator';

@Component({
  name: 'NiceDeny',
})
export default class extends Vue {
  mounted() {
    alert('NICE 인증모듈 오류입니다.');
    window.close();
  }
}
